
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TableMixin from '@/mixins/TableMixin.vue'
// import moment from 'moment'
import AdminBrandForm from '@/views/Admin/Brands/components/AdminBrandForm.vue'
import Brands from '@/apis/Brands'
// import _ from 'lodash'
// import { UserStatusId } from '@/typings/AdminTyping'

@Component({
  name: 'EditBrand',
  components: {
    AdminBrandForm
  }
}
)

export default class EditBrand extends Mixins(TableMixin) {
  simpleTable = true;

  created() {
    this.brandId = this.$router.currentRoute.params.id
    this.fetchDetailsForUpdateCarBrand(
      this.brandId
    )
  }

  brandId: any = null;
  brandDetails = {};
  carModelsForBrand = {};

  carModelsList = {
    headers: [
      {
        text: '№',
        align: 'start',
        value: 'id'
      },
      {
        text: 'Название модели',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Тип',
        align: 'start',
        value: 'is_truck'
      },
      {
        text: 'Состояние',
        align: 'start',
        value: 'with_mileage'
      },
      {
        text: 'Редактировать',
        align: 'start',
        value: 'edit'
      }
    ],
    items: []
  };

  fetchDetailsForUpdateCarBrand(brandId: number) {
    Brands.fetchDetailsForUpdateCarBrand(brandId)
      .then(({ data }) => {
        this.brandDetails = data.brandDetails
        this.carModelsList.items = data.carModelsForBrand
      })
      .catch((errors) => {
        console.log(errors)
      })
  }

  updateBrand() {
    Brands.updateBrand(this.brandId, this.brandDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для сохранения')
      })
  }
}
